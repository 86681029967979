import React from 'react'
import Layout from '../components/layout'
import contactStyles from './contact.module.css'

const ContactPage = () => (
  <Layout>
    <h1 style={{ paddingLeft: '20px' }}>Contact us</h1>
    <div className={contactStyles.container}>
      <form
        className={contactStyles.form}
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className={contactStyles.formRow}>
          <input
            className={contactStyles.input}
            type="text"
            name="name"
            id="name"
            placeholder="Your name"
          />
        </div>
        <div className={contactStyles.formRow}>
          <input
            className={contactStyles.input}
            type="tel"
            name="phone"
            id="phone"
            placeholder="Your phone number"
          />
        </div>
        <div className={contactStyles.formRow}>
          <input
            className={contactStyles.input}
            type="email"
            name="email"
            id="email"
            placeholder="Your email address"
          />
        </div>
        <div className={contactStyles.formRow}>
          <textarea
            className={contactStyles.input}
            name="message"
            id="message"
            rows="6"
            placeholder="Your message"
          />
        </div>
        <div className={contactStyles.buttonContainer}>
          <button className={contactStyles.button} type="submit">
            Send message
          </button>
        </div>
      </form>
      <div style={{ paddingLeft: '20px', fontSize: '1.2rem' }}>
        <div style={{ marginBottom: '10px' }}>
          Email:{' '}
          <a href="mailto:jason@nswcivil.com.au">jason@nswcivil.com.au</a>
        </div>
        <div>
          Phone: <a href="tel:0468600545">0468600545</a>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
